const button = (
	<button class="button is-primary is-large"
		style="width: 300px; height: 75px;"
	>
		<span style="font-family: 'Fredoka One'; font-size: 1.5em">
			Play
		</span>
	</button>
)

export default button
